jQuery(document).ready(function($){

	$('.parallax-bg').jarallax({
	  // parallax effect speed. 0.0 - 1.0
	  speed             : 0.5
	});

	// Defining a function to set size for Slideshow Fullwidth screen 
			var header_banner = $('.banner'),
					home_top_banner = $('.home-top-banner');

	    function fullscreen(){
	        jQuery('.home-hero-image').css({
	            width: jQuery(window).width(),
	            height: jQuery(header_banner).height() + jQuery(home_top_banner).height() + 500
	        });
	    }
	  
	    fullscreen();

	  // Run the function in case of window resize
	  jQuery(window).resize(function() {
	       fullscreen();         
	    });

		  $('#filters').find('button[data-filter=".hso-concerts"]').addClass('is-checked');

		  // init Isotope
		  var $container = $('.filtr-container').isotope({
		    itemSelector: '.filtr-item',
		    layoutMode: 'fitRows',
		    filter: '.hso-concerts',
		    getSortData: {
		      name: '.name',
		      symbol: '.symbol',
		      number: '.number parseInt',
		      category: '[data-category]',
		      weight: function( itemElem ) {
		        var weight = $( itemElem ).find('.weight').text();
		        return parseFloat( weight.replace( /[\(\)]/g, '') );
		      }
		    }
		  });

		  // filter functions
		  var filterFns = {
		    // show if number is greater than 50
		    numberGreaterThan50: function() {
		      var number = $(this).find('.number').text();
		      return parseInt( number, 10 ) > 50;
		    },
		    // show if name ends with -ium
		    ium: function() {
		      var name = $(this).find('.name').text();
		      return name.match( /ium$/ );
		    }
		  };

		  // bind filter button click
		  $('#filters').on( 'click', 'button', function() {
		    var filterValue = $( this ).attr('data-filter');
		    // use filterFn if matches value
		    filterValue = filterFns[ filterValue ] || filterValue;
		    $container.isotope({ filter: filterValue });
		  });

		  // bind sort button click
		  $('#sorts').on( 'click', 'button', function() {
		    var sortByValue = $(this).attr('data-sort-by');
		    $container.isotope({ sortBy: sortByValue });
		  });
		  
		  // change is-checked class on buttons
		  $('.button-group').each( function( i, buttonGroup ) {
		    var $buttonGroup = $( buttonGroup );
		    $buttonGroup.on( 'click', 'button', function() {
		      $buttonGroup.find('.is-checked').removeClass('is-checked');
		      $( this ).addClass('is-checked');
		    });
		  });

		owl = $('#artists-carousel').owlCarousel({
			items: 4,
			margin: 30,
			dots: false,
			loop: true,
			lazyLoad: false,
			smartSpeed: 400,
			responsive : {
		    // breakpoint from 0 up
		    0 : {
		        items: 1
		    },
		    // breakpoint from 480 up
		    480 : {
		        items: 2
		    },
		    // breakpoint from 768 up
		    768 : {
		        items: 3
		    },
		    // breakpoint from 1024 up
		    1024 : {
		        items: 4
		    }
			}
		});

		$(".prev").click(function () {
		    owl.trigger('prev.owl.carousel');
		});

		$(".next").click(function () {
		    owl.trigger('next.owl.carousel');
		});

		owl2 = $('#info-boxes-carousel').owlCarousel({
			items: 1,
			dots: true,
			loop: true,
			lazyLoad: false,
			smartSpeed: 400
		});
});
